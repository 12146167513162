/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match.
 *
 * ======================================================================== */
(function($){
    var Routing = {
        fire : function(func){
            if (func !== '' && Stokvis[func] && typeof Stokvis[func].init == 'function'){
                Stokvis[func].init();
            }
        },

        loadEvents : function(){
            //Fire common js
            Routing.fire('common');
            var pageElement = document.getElementById('page-content');
            var className = (pageElement) ? pageElement.className.split(/\s+/) : [];
            className.forEach(function(val){
                Routing.fire(val);
            });
        }
    };
    Routing.loadEvents();
})(jQuery);