(function ($) {
    var b2cMenu = '.navbar-nav > .dropdown';
    var touchtime = 0;

    //This is for mobil
    $(b2cMenu).bind('touchstart', function(event) {
        event.preventDefault();
        // console.log(event);

        if(this.tagName === 'LI') {
            if(!this.classList.contains('open')) {
                $(b2cMenu).removeClass('open')
            }

            this.classList.add('open');
        }
    });

    var b2cMenu2 = '.navbar-nav a';
    $(b2cMenu2).bind('touchstart', function(event) {
        if (touchtime == 0) {
            // set first click
            touchtime = new Date().getTime();
        } else {
            // compare first click to this click and see if they occurred within double click threshold
            if (((new Date().getTime()) - touchtime) < 800) {
                // double click occurred
                location.href = $(this).attr('href');
                touchtime = 0;
            } else {
                // not a double click so set as a new first click
                touchtime = new Date().getTime();
            }
        }
        if (event.target.classList.length === 0) {
            location.href = $(this).attr('href');
        } else if (this.classList.contains('open')) {
            location.href = $(this).attr('href');
        }
    });
    var b2cMenu3 = '.navbar-nav ';

    //This is for desktop
    $(b2cMenu).hover(b2cMenuHoverEnter, b2cMenuHoverLeave);
    $(b2cMenu + '> a').click(b2cMenuItemClick);

    function b2cMenuHoverEnter() {
        this.classList.add('open');
    }

    function b2cMenuHoverLeave() {
        this.classList.remove('open');
    }

    function b2cMenuItemClick(event) {
        event.preventDefault();

        location.href = this.href;
    }
})(jQuery);
