/**
 * Globally available helper functions
 */

/**
 * Check if users device is touch device
 *
 * Note that we can't be sure if a device is a touch device,
 * especially now devices have touch screens and keyboards and trackpad or mouse
 * So use this only for progressive enhancement
 *
 * When we want the include more browser checks, we maybe better of with Modernzr.
 * But that is a overkill for one function.
 * 
 * @return {Boolean} true if browser support touch events
 */
isTouchDevice = function() {
  var bool = ('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch || navigator.maxTouchPoints ? true : false;
  return bool;
};