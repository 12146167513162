(function ($) {
    $('form#contact-form').on('beforeSubmit', function(e) {
        e.preventDefault();
        var action = $(this).attr('action');
        var form = new FormData($('form')[0]);
        $('.contact-submit').attr('disabled', true)
        $.ajax({
            url: action,
            type: 'POST',
            dataType: 'json',
            contentType: false,
            processData: false,
            data: form
        }).done(function(data) {
            if(data.message) {
                $('#contact-container').html(data.message);
                $('.contact-submit').attr('disabled', false)
            }
        }).fail(function() {
        });
        return false;
    }).on('submit', function(e){
        e.preventDefault();
    });

    $('form#request-form').on('beforeSubmit', function(e) {
        e.preventDefault();
        var form = $(this);
        $.post(form.attr("action"), form)
            .done(function(data) {
                if (data.message) {
                    $('#request-container').html(data.message);
                }
            }).fail(function() {
            }
        );
        return false;
    }).on('submit', function(e){
        e.preventDefault();
    });
})(jQuery);
