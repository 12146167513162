(function($){

  /**
   * Control of the main Navbar.
   *
   * This object contains two big functionalities:
   * 1. Responsive nav with flexible items
   * 2. Hover states for dropdowns on desktop-size non-touch devices.
   * 
   * @type {Object}
   */
  var Navigation = {

    /**
     * jQuery Menu obj
     */
    $menu: $('.js-nav'),

    /**
     * jQuery Menu Container obj
     */
    $container: $('.js-nav .nav-container'),
    
    /**
     * Check the width of the device based on nav width
     * @type {Boolean}
     */
    isSmallDevice: true,

    /** 
     * Screen width where we should always show the mobile menu
     * @type {Number}
     */
    alwaysMobileBP: 768,

    /**
     * Screen width where we should always show the desktop menu
     * @type {Number}
     */
    alwaysDesktopBP: 1170,

    /**
     * Check if loaded once for initial setup
     */
    loadedOnce: false,

    /**
     * Initialize this object
     */
    init: function() {
      this.responsiveNav();
      this.showHoverMenuDropdowns();
    },

    /**
     * Make the nav responsive for variable content
     */
    responsiveNav: function() {

      var obj = this;

      /* 
       * Debounce the resize for better preformance
       * On resize, run the function and reset the timeout
       */
      $(window).on('resize', function() {
        obj.initResponsiveMenuClass();
      });

      obj.initResponsiveMenuClass()
      this.loadedOnce = true;
    },

    /**
     * Initialize the responsive class based on the screen width
     */
    initResponsiveMenuClass: function() {
      var windowWidth = $(window).width();


      // If the screen is smaller than the minium breakpoint, force mobile
      if ( windowWidth < this.alwaysMobileBP) {
        if (!this.isSmallDevice || !this.loadedOnce) {        
          this.isSmallDevice = true;
          this.toggleResponsiveNavClass('mobile');
          return false;
        }
      }

      // If the screen is bigger than the maximum breakpoint, force desktop
      if ( windowWidth >= this.alwaysDesktopBP) {
        if (this.isSmallDevice) {        
          this.isSmallDevice = false;
          this.toggleResponsiveNavClass('desktop');
          return false;
        }
      }

      // If the screen is between the breakpoint and we're coming from desktop
      // check the menu width and decide wat we should do based on the width
      this.getMenuWidth();
    },

    /**
     * Return the width of the menu
     * 
     */
    getMenuWidth: function() {

        var menuMaxWidth = 0;

        // Get the width of the container
        menuMaxWidth = this.$menu.children('.container').width();

        // Substract the logo width
        menuMaxWidth -= this.$menu.find('.navbar-brand').width();
        
        // Substract the width of the navbar buttons
        // The buttons are 85px on desktop
        menuMaxWidth -= 165;

        this.checkContainerMenuFit(menuMaxWidth);
    },


    /**
     * Check if the menu fits in the container
     */
    checkContainerMenuFit: function(menuMaxWidth) {
      // Only check if we're coming from desktop, we can't measure the menu width from mobile
      if (!this.isSmallDevice || !this.loadedOnce){
        // if the actual menu is bigger than the supposed width
        if (this.$container.children('ul').width() + 20 > menuMaxWidth ) {
          this.isSmallDevice = true;
          this.toggleResponsiveNavClass('mobile');
        } else {
          this.isSmallDevice = false;
          this.toggleResponsiveNavClass('desktop');
        }        
      }
    },
    
    /**
     * Toggle navClasses based on the menu-width
     * 
     * @param  {string} type type of menu we want
     */
    toggleResponsiveNavClass: function(type) {

      if (type === 'mobile'){
        this.$menu.removeClass('is-desktop').addClass('is-mobile');                  
      }
    
      if (type === 'desktop'){
        this.$menu.addClass('is-desktop').removeClass('is-mobile');
      }      
    },
    /**
     * Handle main navigation anchor clicks
     *
     * When we're on a touch device the first click opens the submenu
     * A second click will trigger the default behaviour (following to a link).
     */
    showHoverMenuDropdowns: function() {

      // Get the main nav links that have submenus
      var menuAnchors = Array.prototype.slice.call(document.querySelectorAll('.navbar-main .nav > li.dropdown'));

      this.showDropdownOnHover(menuAnchors);
      this.handleDropdownParentAnchor(menuAnchors);
      
    },

    /**
     * Open dropdown submenu on hover
     *
     * Add class open to parent item of a dropdown menu.
     * The class 'open' causes the dropdown-menu to open.
     * 
     * @param  {array}   menu-items   [array of menu-items width a dropdown classlist]
     */
    showDropdownOnHover: function(menuItems) {
      var obj = this;

      if (!isTouchDevice() && menuItems.length > 0) {

        menuItems.forEach( function(item) {

          // Add class on hover
          item.addEventListener('mouseenter', function(event) {
            // Only on desktop navigation
            if (!obj.isSmallDevice) {
              item.classList.add('open');
            }
          });

          // Remove class on mouse leave
          item.addEventListener('mouseleave', function(event) {
            // Only on desktop navigation
            if (!obj.isSmallDevice) { 
              item.classList.remove('open');
            }
          });

          // Go to direct child link on click
          item.addEventListener('click', function(event) {

            // On mobile menu view add class for double click option
            if (obj.isSmallDevice && !item.classList.contains('js-clicked-once')) {
                item.classList.add('js-clicked-once');
                // item.classList.add('open'); Bootstrap does this already!
                event.preventDefault();
            // Only on desktop navigation and only on mobile view if the item is already clicked
            } else if (item.children.length > 0) {
              for (i = 0; i < item.children.length; i++) {
                var itemChild = item.children[i];
                // If direct child is anchor
                if (itemChild.nodeName === 'A') {
                  // store href for later access
                  location.href = itemChild.href;
                }
              }
            }
          });
        });     
      }
    },

    /**
     * Open dropdown menu on item click
     * Go to link on double click
     * 
     * @param  {array}      menu-items   [array of menu-items width a dropdown classlist]
     * @return  {boolean}   navigate on click to anchor childs href
     */
    handleDropdownParentAnchor: function(menuItems) {

      if (isTouchDevice() && menuItems.length > 0 ) {
        menuItems.forEach(function(item) {
          var parentAnchorLink;

          // Disable link navigation on link-item direct item children
          if (item.children.length > 0) { 
            for (i = 0; i < item.children.length; i++) {
              var itemChild = item.children[i];
              // If direct child is anchor
              if (itemChild.nodeName === 'A') {
                itemChild.addEventListener('click', function(event){
                  // disable link
                  event.preventDefault();
                });
                // store href for later access
                parentAnchorLink = itemChild.href;
              }
            }
          }

          item.addEventListener('click', function(event) {
              
            // Forward to link if already clicked
            if (item.classList.contains('js-clicked-once') && parentAnchorLink) {
              // Ge stored direct child anchor href and go there
              location.href = parentAnchorLink;
              return true;
            }

            // Remove all possible other clicked links
            menuItems.forEach(function(siblingAnchor) {
              siblingAnchor.classList.remove('js-clicked-once');
            });

            console.log('Log message');

            // Add clicked class for next reference
            item.classList.add('js-clicked-once');
            return false;
          });
        });
      }
    },
  }

  // Initialize our object
  Navigation.init();

})(jQuery);