$(function(){


    function doAjax(element){
        url = window.location.protocol+'//'+window.location.hostname+'/ajax';

        query = $(element).attr('query');
        if ($(element).attr('data-val')){
            if (query){
                query += '&val='+$(element).attr('data-val');
            } else {
                query = '';
                query += 'val='+$(element).attr('data-val');
            }
        }

        query = query.split('&');

        data = {};
        if (query.length > 0){
            for (i = 0; i < query.length; i++){
                if (query[i] != 'undefined'){
                    q = query[i].split('=');
                    if (q[0] != 'undefined' && q[1] != 'undefined'){
                        data[q[0]] = q[1];
                        if (q[1] == 'this'){
                            data[q[0]] = $(element).val();
                        }
                    }
                }
            }
        } else {
            return;
        }
        $.ajax({
            url: url,
            type: 'GET',
            crossDomain: false,
            data: data,
            success: function(response){
                try{
                    data = $.parseJSON(response);
                    if (data.success){
                        if (data.message && !data.reload){
                            var m = '';
                            if (data.error) {
                                m = '<div id="w2-danger-0" class="ajax-alert alert-danger alert fade in container">';
                            } else {
                                m = '<div id="w2-success-0" class="ajax-alert alert-success alert fade in container">';
                            }

                            m += '<button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>';
                            m += data.message;
                            m += '</div>';

                            $('header').last().append(m);
                            $('.ajax-alert').fadeIn('slow');

                            if ($('.ajax-alert')){
                              $('html, body').animate({
                                  scrollTop: $(".ajax-alert").offset().top-20
                              }, 200);
                            }
                        }

                        if(data.remove){
                            $('#'+data.remove).remove();
                        }

                        if (data.reload){
                            window.location.reload();
                        }
                    }
                }catch(response){
                    console.log('Error parsing json.');
                }
            },
            beforeSend: function(){
              $('.ajax-alert').remove();
            }
        }).done(function(){

        }).fail(function(){

        });
    }

    $(document).delegate('.ajax-action', 'click', function(){
        doAjax($(this));
    });

    $(document).delegate('.item-quantity-controls a','click', function(){
        i = $(this).parent().find('input');
        doAjax(i);
        $(this).addClass('hide');
    });

    $(document).delegate('.ajax-action-onchange', 'propertychange change keyup input page', function(){
        $(this).parent().find('a').removeClass('hide');
    });

    $(document).delegate('.js-input-number', 'propertychange change keyup input page', function(){
        if ($(this).val() < 1){
            $(this).val(1);
        }

        if ($(this).attr('data-target')){
            el = $($(this).attr('data-target'));
            val = $(this).val();
            el.attr('data-val', val);
        }
    });

});
