"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var LightBox = function () {
    function LightBox() {
        _classCallCheck(this, LightBox);

        this.domBox = $("\n            <div class=\"lightbox-wrapper hidden\">\n                <div class=\"lightbox\">\n                    <div class=\"lightbox-image-wrapper\">\n                        <div class=\"lightbox-close\"><span class=\"x\">X</span></div>\n                        <img class=\"lightbox-image\"/>\n                    </div>\n                    <div class=\"lightbox-caption-wrapper\">\n                        <span class=\"lightbox-caption\"></span>\n                    </div>\n                </div>\n            </div>\n        ");
        $(document.body).append(this.domBox);
        this.domBox.click(this.onWrapperClick.bind(this));
        this.domBox.find(".lightbox").click(this.onBoxClick.bind(this));
        this.domBox.find(".lightbox-close").click(this.hide.bind(this));

        $(".lightboxable").click(this.loadImage.bind(this));

        this.hide();
    }

    _createClass(LightBox, [{
        key: "loadImage",
        value: function loadImage(event) {
            var img = event.currentTarget;
            var jqueryImage = $(img);

            var theImage = new Image();
            theImage.src = jqueryImage.attr("src");

            this.domBox.find(".lightbox-image").attr("src", jqueryImage.attr("src")).css("width", theImage.width + "px").css("height", theImage.height + "px");
            this.domBox.find(".lightbox-caption").html(jqueryImage.attr("data-caption"));
            this.domBox.find("lightbox");
            this.show();
        }
    }, {
        key: "show",
        value: function show() {
            this.domBox.removeClass("hidden");
            $(document.body).css("overflow", "hidden");
        }
    }, {
        key: "hide",
        value: function hide() {
            this.domBox.addClass("hidden");
            this.domBox.find(".lightbox-image").css("width", "0").css("height", "0");
            $(document.body).css("overflow", "");
        }
    }, {
        key: "onBoxClick",
        value: function onBoxClick(event) {
            event.stopPropagation();
        }
    }, {
        key: "onWrapperClick",
        value: function onWrapperClick(event) {
            this.hide();
        }
    }]);

    return LightBox;
}();

$(document).ready(function () {
    var lightBoxables = $(".lightboxable");
    if (lightBoxables.length > 0) {
        new LightBox();
    }
});