(function(jQuery) {

        $('.js-cookie-button').on('click', function() {
            document.cookie = "CookiesAccepted=true; expires=Fri, 01 Jan 2100 00:00:00 GMT\"";
            location.reload();
        });

        if (/(^|;)\s*CookiesAccepted=/.test(document.cookie)) {
            var element = document.getElementById('cookie-bar');
            element.classList.add('inactive');
        }
})($);