(function ($) {
    if($('#dynamicmodel-order_type').val() == 1) {
        $('.delivery-method').addClass('hidden');
        $('#dynamicmodel-order_type option[value=AFG]').attr('selected','selected');
    } else {
        $('.delivery-method').removeClass('hidden');
    }

    $('#dynamicmodel-order_type').on('change', function() {
        var selected = $('#dynamicmodel-order_type').val();
        if(selected == 1) {
            $('.delivery-method').addClass('hidden');
            $('.payment-methods').addClass('hidden');
            $('#dynamicmodel-order_type option[value=AFG]').attr('selected','selected');
        } else {
            $('.delivery-method').removeClass('hidden');
            $('.payment-methods').removeClass('hidden');
        }
    });

    $('input[name=account_checkout]').on('change', function() {
        var value = $(this).val();
        if(value == 1) {
            $('.checkout-login').addClass('hidden');
            $('.checkout-register').removeClass('hidden');
        } else if(value == 2) {
            $('.checkout-login').removeClass('hidden');
            $('.checkout-register').addClass('hidden');
        } else {
            $('.checkout-login').addClass('hidden');
            $('.checkout-register').addClass('hidden');
        }
    });

    $('.multiple-items').slick({
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2
    });
    $('.single-item').slick();
    $('.multiple-items-large').slick({
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 6
    });

    $('a[data-toggle=\"tab\"]').on('show.bs.tab', function (e) {
        localStorage.setItem('lastTab', $(this).attr('href'));
    });

    var lastTab = localStorage.getItem('lastTab');

    if(lastTab) {
        $('[href=\"' + lastTab + '\"]').tab('show');
    }
})(jQuery);
$body = $("body");

/*** JAVASCRIPT BY SIEBRAND ***/

// trustbar-xs slider 
window.onload = function() {
    var quotes = $(".trustbar-xs-item");
    var quoteIndex = -1;

    function showNextQuote() {
        ++quoteIndex;
        quotes.eq(quoteIndex % quotes.length)
        .fadeIn(1000)
        .delay(3000)
        .fadeOut(1000, showNextQuote);
    }
    showNextQuote();
};


// Hide / Show toggle checkbox
function toggleDiv(chkBilling) {
    var shippingInfo = document.getElementById("shippingInfo");
    shippingInfo.style.display = chkBilling.checked ? "none" : "block";
}

// Dropdown 
function dropDown (element) {
   $(element).slideToggle("fast");
}

$("#btn-select-categorie").click(function() {
    $(this).toggleClass('open-info-payment');
    dropDown(".select-categorie-xs");
});

$(".category-list").click(function() {
    $("#icon-category-list-toggle").toggleClass('fa-chevron-down fa-chevron-up');
    dropDown(".category-list__hidden-categories");
});

$(".searchbar-btn-dropdown").click(function() {
    $(".search-dropdown-extend").toggleClass('u-none');
    $("#icon-search-toggle").toggleClass('fa-chevron-down fa-chevron-up');
});

$('#content-search').on('change', function () {
   var q = $('#search-extra').prop('href');

});
