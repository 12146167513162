var Stokvis = {
    // JS that fires on all pages
    common:{

        screensize: {
            xs:480,
            sm:768,
            md:992,
            lg:1200,
        },

        init: function() {
            this.setDeviceClasses();
            this.tabs();
            this.showNoSearchResults();
            this.objectFitPolyFill();
        },

        setDeviceClasses: function() {
            var bodyClass = isTouchDevice() ? 'is-touch' : 'is-not-touch';
            document.body.classList.add(bodyClass);
        },

        /**
         * IE Polyfill for slides with object-fit images
         */
        objectFitPolyFill: function(){
            if (typeof objectFitImages != 'undefined') objectFitImages();
        },


        /**
         * Responsive navigation for Bootstrap tabs
         */
        tabs: function() {
            $(document).on('show.bs.tab', '.nav-tabs-responsive [data-toggle="tab"]', function(e) {
                var $target = $(e.target);
                var $tabs = $target.closest('.nav-tabs-responsive');
                var $current = $target.closest('li');
                var $parent = $current.closest('li.dropdown');
                $current = $parent.length > 0 ? $parent : $current;
                var $next = $current.next();
                var $prev = $current.prev();
                var updateDropdownMenu = function($el, position){
                    $el
                        .find('.dropdown-menu')
                        .removeClass('pull-xs-left pull-xs-center pull-xs-right')
                        .addClass( 'pull-xs-' + position );
                };

                $tabs.find('>li').removeClass('next prev');
                $prev.addClass('prev');
                $next.addClass('next');

                updateDropdownMenu( $prev, 'left' );
                updateDropdownMenu( $current, 'center' );
                updateDropdownMenu( $next, 'right' );
            });
        },

        /**
         * Show 1 no search results message if no results found with typeehead
         *
         * Each dataset in the search typeehead function has a no results message.
         * But we only want to show one message.
         *
         * By binding an event to de typeheadrender functions we can filters those messages if no results are found
         */
        showNoSearchResults: function() {
            var $searchForm = $('.search-form');

            // Bind typeahead event to the render function
            $searchForm.find('#content-search').bind('typeahead:render', function(ev, suggestion) {
                var suggestions = $searchForm.find('.tt-suggestion.tt-selectable');

                var nones = $searchForm.find('.tt-none');
                nones.hide();

                // Only show the first message if there are zero results available
                if(suggestions.length === 0) {
                    nones.first().show();
                }
            });
        },
    },

    //Js that fires on contact
    contact: {

        map: null,
        markers: [],
        init: function(){

            //Check if we have data or else don't show div
            if( GoogleMapsData !== undefined &&
                !isNaN(parseFloat(GoogleMapsData.latitude)) &&
                !isNaN(parseFloat(GoogleMapsData.longitude)) &&
                GoogleMapsData.locations.length > 0
            ){
                // Initialize maps
                this.googleMaps();
                $('.maps-filter input').on('change', this.filterMarkers);

                google.maps.initialZoom = true;
                google.maps.event.addListenerOnce(Stokvis.contact.map, 'zoom_changed', function() {
                    if (Stokvis.contact.markers.length == 1) {
                        Stokvis.contact.map.setZoom(12);
                    }

                    // Only show the world 'once' by forcing a max zoom on initial zoom
                    if (this.getZoom() < 3 && google.maps.initialZoom ) {
                        this.setZoom(3);
                    }

                    this.initialZoom = false;
                });
            } else {
                $('.google-maps-container').hide();
            }
        },

        bindInfoWindow: function() {
            this.setMarkers();
            this.autoCenter();
        },

        setMarkers: function() {
            var locations = GoogleMapsData.locations;

            if( !locations && typeof locations !== 'object') return false;

            this.markers = locations.map(function(location){
                // Create coordinates
                var latlngset = new google.maps.LatLng(location.position.latitude, location.position.longitude);

                var markerImage = new google.maps.MarkerImage('../../img/' + location.type.icon,
                    new google.maps.Size(30, 37),
                    new google.maps.Point(0, 0),
                    new google.maps.Point(0, 15));

                // Create marker
                var marker = new google.maps.Marker({
                    icon: markerImage,
                    position: latlngset,
                    map: Stokvis.contact.map,
                    title: location.name,
                    desc: location.content,
                    type: location.type,
                });

                // Create info window
                var html= "<div style='color:#000;background-color:#fff;padding:5px;width:150px;'><h4>"+location.name+"</h4><p>"+location.content+"<p></div>";
                //add opened attribute to infowindow class
                google.maps.InfoWindow.prototype.opened = false;
                var iw = new google.maps.InfoWindow();
                iw.setContent(html);
                if (length == 1) {
                    iw.open(Stokvis.contact.map, marker);
                    iw.opened = true;
                }
                google.maps.event.addListener(marker, 'click', (function(marker, html) {
                    return function(evt) {
                        if(!iw.opened) {
                            iw.setContent(html);
                            iw.open(Stokvis.contact.map, marker);
                            iw.opened = true;
                        } else {
                            iw.close();
                            iw.opened = false;
                        }
                    };
                })(marker, html, iw));

                return marker;
            });
        },

        autoCenter: function(){
            var bounds = new google.maps.LatLngBounds();

            this.markers.forEach( function(marker) {
                bounds.extend(marker.position);
            });
            Stokvis.contact.map.fitBounds(bounds);

        },

        googleMaps:function(){

            var mapElement = document.getElementById('google-map');
            var mapOptions = {
                center: new google.maps.LatLng(GoogleMapsData.latitude, GoogleMapsData.longitude),
                zoom: 15,
                zoomControl: true,
                zoomControlOptions: {
                    style: google.maps.ZoomControlStyle.DEFAULT,
                },
                backgroundColor: '#000',
                disableDoubleClickZoom: true,
                overviewMapControl: false,
                mapTypeControl: false,
                scaleControl: true,
                scrollwheel: false,
                panControl: true,
                streetViewControl: true,
                draggable : true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [
                    {
                        "featureType": "all",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "saturation": 36
                            },
                            {
                                "color": "#343434"
                            },
                            {
                                "lightness": 40
                            }
                        ]
                    },
                    {
                        "featureType": "all",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "color": "#ececec"
                            },
                            {
                                "lightness": 16
                            }
                        ]
                    },
                    {
                        "featureType": "all",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 20
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#fbfbfb"
                            },
                            {
                                "lightness": 17
                            },
                            {
                                "weight": 1.2
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#e6e6e6"
                            },
                            {
                                "lightness": 20
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#d4d4d4"
                            },
                            {
                                "lightness": 21
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#bfbfbf"
                            },
                            {
                                "lightness": 21
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#b8b8b8"
                            },
                            {
                                "lightness": 17
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#e0e0e0"
                            },
                            {
                                "lightness": 29
                            },
                            {
                                "weight": 0.2
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f3f3f3"
                            },
                            {
                                "lightness": 18
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 16
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f2f2f2"
                            },
                            {
                                "lightness": 19
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#c2c2c2"
                            },
                            {
                                "lightness": 17
                            }
                        ]
                    }
                ]                }

            Stokvis.contact.map = new google.maps.Map(mapElement, mapOptions);

            Stokvis.contact.bindInfoWindow();
        },

        /*
         * Filter markers based on checbox value
         */
        filterMarkers: function(){

            var $checkbox = $(this);
            var CBValue = $checkbox.val();

            // If the checkbox is checked show markers
            if($checkbox.prop('checked')){
                $.each(Stokvis.contact.markers, function(index, marker) {
                    if (marker.type.slug === CBValue) {
                        marker.setVisible(true);
                    }
                });

                // If the checkbox is checked hide markers
            } else {
                $.each(Stokvis.contact.markers, function(index, marker) {
                    if (marker.type.slug === CBValue) {
                        marker.setVisible(false);
                    }
                });
            }

        },
    },

};