$(document).ready(function() {
    $(".item-quantity-controls-save").click(function(event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        var element = event.currentTarget;
        var jElement = $(element);
        var form = jElement.parent("form");
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success: function(data) {
                jElement.addClass("hide");

                var formatter = new Intl.NumberFormat('nl-NL', {
                    style: 'currency',
                    currency: 'EUR',
                    minimumFractionDigits: 2,
                });

                $(".js-subtotal").html("&euro;" + data.subtotal);
                $(".js-shipping").html("&euro;" + data.shipping);
                $(".js-tax").html("&euro;" + data.tax);
                $(".js-total").html("&euro;" + data.total);
                $(".cart-qty").html(data.itemCount);
            }
        });
    });
});

function updateCartQuantity(qty) {
    var cart = document.getElementsByClassName('cart-qty');
    if(qty) {
        var quantity = parseInt(document.getElementsByName('cart-quantity-placeholder')[0].value) + parseInt(qty);
    } else {
        var quantity = document.getElementsByName('cart-quantity-placeholder')[0].value;
    }
    for(var i = 0; i < cart.length; i++) {
        if(quantity < 1) {
            cart[i].outerHTML = '';
            continue;
        }

        cart[i].innerText = quantity;
    }
}